jQuery(function ($) {

	const debounce = ( fn, time = 1000 ) => {

		if ( !fn ) {
			throw Error( '"debounce function - "You didn\'t add required parameters' );
		}

		let timeout;

		return function () {
			const functionCall = () => fn.apply( this, arguments );

			clearTimeout( timeout );
			timeout = setTimeout( functionCall, time );
		}
	};

    //filter
    $('.filter_libr ').on('click', function (event) {
        // event.preventDefault();
        $('.filter_post').slideToggle(500);
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
        } else {
            $(this).addClass('active');
        }
    });

    //ajax search dates
    const $dateTo   = $("#date_to");
    const $dateFrom = $("#date_from");
    const $slickSliderSingle = $('.slick-slider-single');

    if($dateTo.length) {
        $dateTo.datepicker({
            dateFormat: 'yy-mm-dd'
        });
    }

    if($dateFrom.length) {
        $dateFrom.datepicker({
            dateFormat: 'yy-mm-dd',
            onSelect: function (date) {

                var selectedDate = new Date(date);
                var msecsInADay = 86400000;
                var endDate = new Date(selectedDate.getTime() + msecsInADay);

                $("#date_to").datepicker("option", "minDate", endDate);
                $("#date_to").datepicker("option", "maxDate", '+2y');

            }
        });
    }

    if($slickSliderSingle.length) {
        $slickSliderSingle.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slick-slider-nav',
            draggable: false
        });
    }

    $('.slick-slider-nav').on('init', function(event, slick){
        //init code goes here
    }).on('afterChange',function(e,o){
        //on change slide = do action
        console.log('asdasaa');
        $('iframe').each(function(){
            $(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
            $(this)[0].contentWindow.postMessage('{"method":"pause"}', '*');
        });
    }).slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.slick-slider-single',
        dots: false,

        focusOnSelect: true,
        arrows: true,
        infinite: true,
        prevArrow: '<div class="slide-arrow prev-arrow"></div>',
        nextArrow: '<div class="slide-arrow next-arrow"></div>',
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        }, {
            breakpoint: 640,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            }
        }, {
            breakpoint: 420,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        }]
    });


    var ajaxSearchLibrBar = $(".search_bar.library_search"),
        ajaxLibrForm = ajaxSearchLibrBar.find("form"),
        pageNumber = 2,
        loadMoreBtnLibr = $(".library-blog .btn_load_more_block"),
        visibleBlocks = $( ".inner_posts .result_search_articles article" ).length,
        loadMoreBtnNews = $(".content-newsletter-blocks .btn_load_more_block"),
        ajaxSearchNewsBar = $(".search_bar.news_search"),
        ajaxDefSearchNewsBar = $(".search_bar.def_search"),
        ajaxNewsForm = ajaxSearchNewsBar.find("form"),
        ajaxDefNewsBar = ajaxDefSearchNewsBar.find("form"),
        resetFilterLibr = $(".library_search .reset-btn");

    resetFilterLibr.on("click", function (e) {
        pageNumber = 2;
        e.preventDefault();
        ajaxLibrForm.find("#keyword").val('');
        ajaxLibrForm.find("#date_from").val('');
        ajaxLibrForm.find("#date_to").val('');

        $(".cat_chckbx:checkbox").each(function () {
            $(this).prop('checked', true);
        });

        $('.inner_posts  article').remove();

	    ajaxSearchLibrary();
    });

	let canBeLoaded = false;

	function isInViewport(el, offset = 100) {
		if (!el) {
			throw Error(
				'"isInViewport function - "You didn\'t add required parameters'
			);
		}

		const scroll = window.scrollY || window.pageYOffset;
		const boundsTop = el.getBoundingClientRect().top + offset + scroll;

		const viewport = {
			top: scroll,
			bottom: scroll + window.innerHeight,
		};

		const bounds = {
			top: boundsTop,
			bottom: boundsTop + el.clientHeight,
		};

		return (
			(bounds.bottom >= viewport.top)
		);
	}

	function ajaxSearchLibrary(numberPage= 1) {

		let arrayCats = [],
			typesPosts = [];

		var numItems = $('.library-blog .all_posts').length;

		$('.inner_showing_result .col_res').val(numItems);

		$(".cat_chckbx:checkbox:checked").each(function () {
			if ($(this).attr('name') != 'newsletters' && $(this).attr('name') != 'allitems') {
				arrayCats.push($(this).val());
				typesPosts.push($(this).attr('data-type'));
			} else{
				typesPosts.push($(this).attr('data-type'));
			}
		});

		if (ajaxLibrForm.find("#keyword").val().length !== 0) {
			var keyword = ajaxLibrForm.find("#keyword").val();
		}

		if (ajaxLibrForm.find("#date_from").val().length !== 0) {
			var start = $("#date_from").datepicker({ dateFormat: 'yyyy-mm-dd' }).val();
		}

		if (ajaxLibrForm.find("#date_to").val().length !== 0) {
			var finish = $("#date_to").datepicker({ dateFormat: 'yyyy-mm-dd' }).val();
		}

		let arrayCatsString = arrayCats.toString();

		let unique = [...new Set(typesPosts)];

		const postType = unique.filter((item) => item!=='');

		jQuery.ajax({
			url: ajax_url,
			type: 'POST',
			data: {
				action: 'library_ajax_filter_search_callback',
				search: keyword,
				categories: arrayCatsString,
				startDate: start,
				finishDate: finish,
				typesPosts: postType,
				pageNumber: numberPage,
			},
			success: function (response) {
				let $response = $(response);

				if ($response.length) {
					$('#datafetch .inner_posts').append(response);
					if(numberPage !== 1) {
						pageNumber++;
					}
				} else {
					loadMoreBtnLibr.hide();
				}

				canBeLoaded = false;
				$(countAssotiatedBlocks);

			}
		});

	}

	function isLoadMore (el) {
		const boundsTop = el.getBoundingClientRect().top;
		const windowHeight = window.innerHeight;
		return (boundsTop<windowHeight);
	}

	window.addEventListener('scroll', (event) => {

		const loadMoreButton = document.querySelector('.content-library-blocks .btn_load_more_block');
		const loadMoreNewsletterButton = document.querySelector('.content-newsletter-blocks .btn_load_more_block');

            if(loadMoreButton && isLoadMore(loadMoreButton) && !canBeLoaded ) {
                canBeLoaded = true;
	            ajaxSearchLibrary(pageNumber);
            }

            if(loadMoreNewsletterButton && isLoadMore(loadMoreNewsletterButton) && !canBeLoaded) {
	            canBeLoaded = true;
	            ajaxNewsletterSearch(pageNumber, false);
            }

	})

    loadMoreBtnNews.on("click", function (e) {
        e.preventDefault();
	    ajaxNewsletterSearch(pageNumber, false);
    });

    ajaxLibrForm.submit(function (e) {
        e.preventDefault();

        $("#datafetch .inner_posts article").remove();

        ajaxSearchLibrary();

    });

    $(".search_bar.library_search ").on("click", ".search_icon", function (e) {

        e.preventDefault();
        $("#datafetch .inner_posts article").remove();
        ajaxSearchLibrary();
        // console.log("form submitted libr click_search");

    });

    // Start Newsletter Search submit and click on Search BTN
    //**

    function countBlocksSearch() {
        var n = $( ".inner_posts .result_search_articles article" ).length;
        console.log('count '+ n);
        $(".col_res").text(n);
    }

    function countAssotiatedBlocks() {
        var count = parseInt($('.inner_posts  article:visible').length);
        $(".col_res").text(count);
    }
    //book score
    function countBooksStore() {
        // count = 0;
        var countD = $('#nav-digital  li:visible').length;
        var countP = $('#nav-printed  li:visible').length;
        $("#nav-digital .res_count").text(countD);
        $("#nav-printed .res_count").text(countP);
    }
    countBooksStore();

    $(document).on("click", '#nav-printed-tab', function () {
        countBooksStore();
        console.log('click');
    });


    $(document).ready(function() {
        var n = $( ".inner_posts  article" ).length;
        $(".col_res").text(n);
    });

	//debounce

    // Main Shop search
    $("#books-search").on("keyup", debounce(function (event) {
        const searchValue = $(this).val().toLowerCase();

        if(searchValue) {
	        event.target.classList.add('search-active');
        } else {
	        event.target.classList.remove('search-active');
        }

		const activeTabLink = document.querySelector('.js-nav-products .nav-link.active');
        if(!activeTabLink) return;

        const type = activeTabLink.dataset.type;
        const search = activeTabLink.dataset.search;

	    const data = new FormData();
	    data.append( 'action', 'search_product' );
	    data.append( 'search', search );
	    data.append( 'search_value', searchValue );
	    data.append( 'type', type );

	    fetch(ajax_url,
		    {
			    method: "POST",
			    credentials: 'same-origin',
			    body: data
		    })
		    .then((response) => response.json())
		    .then((dataResponse) => {
		    	if(!dataResponse.success) return;

		    	const tabActive = document.querySelector('.tab-pane.active');
		    	if(!tabActive) return;
		    	const productList = tabActive.querySelector('.custom-product-list');
		    	const showMore = tabActive.querySelector('.js-product-load-more');
			    showMore.dataset.offset = 9;

			    productList.innerHTML = dataResponse.data;
			    const allFindProduct = productList.querySelectorAll('.product');

			    if(allFindProduct.length < 9) {
				    showMore.style.display = 'none';
				    showMore.classList.add('hide');
			    } else {
				    showMore.style.display = 'block';
				    showMore.classList.remove('hide');
			    }

		    })

    }));


    $(document).on("click", '.facilitator-ask', function () {
        popup_accept('facilitator');
    });

    function popup_accept(name){
        if (name == 'facilitator'){
            $('.popup_message_access').addClass('disable');
            $('.popup_facilitator').removeClass('disable');
            $('.facilitator-ask').addClass('disable');
            $('.overlay_message_popup').fadeIn();
            $('.overlay-popup').fadeIn();
        }
        else if (name == 'article'){
            $('.overlay_message_popup').fadeIn();
            $('.overlay-popup').fadeIn();
        }
        else if (name == 'add_to_cart'){
            $('.popup_message_access').addClass('disable');
            $('.popup_add_to_cart').removeClass('disable');
            $('.overlay_message_popup').fadeIn();
            $('.overlay-popup').fadeIn();
        }
    }

    $('#mySelect2').on('select2:select', function (e) {
        // Do something
    });

    function ajaxNewsletterSearch(numberPage = 1, searchAction = true) {
	    let keyword = '';
        if (ajaxNewsForm.find("#keyword").val().length !== 0) {
             keyword = ajaxNewsForm.find("#keyword").val();
        }

        jQuery.ajax({
            url: ajax_url,
            type: 'POST',
            data: {
                action: 'newsletter_ajax_filter_search_callback',
                search: keyword,
                typesPosts: 'newsletter',
	            pageNumber: numberPage,
            },
            success: function (response) {
            	if(searchAction) {
		            $("#datafetch .inner_posts article").remove();
	            }

                if(response) {
	                $('#datafetch .inner_posts').append(response);

	                if(numberPage !== 1) {
		                pageNumber++;
	                }

                } else {
	                loadMoreBtnNews.hide();
                }

	            canBeLoaded = false;
	            $(countAssotiatedBlocks);
            }
        });
    }

    ajaxNewsForm.submit(function (event) {
	    event.preventDefault();
	    ajaxNewsletterSearch();
    });

    $(".search_bar.def_search ").on("click", ".search_icon", function (e) {
        console.log('asd');
        e.preventDefault();
        if (ajaxDefNewsBar.find("#keyword").val().length !== 0) {
            var keyword = ajaxDefNewsBar.find("#keyword").val();
        }
        console.log(keyword);
        jQuery.ajax({
            url: ajax_url,
            type: 'POST',
            data: {
                action: 'search_ajax_filter_search_callback',
                search: keyword,
                // typesPosts: 'newsletter'
            },
            success: function (response) {
                $("#datafetch .inner_posts article").remove();
                $('#datafetch .inner_posts').append(response);
                $(countAssotiatedBlocks);
                loadMoreBtnNews.hide();
            }
        });
    });

    $(".search .search_bar.news_search ").on("click", ".search_icon", function (e) {

        e.preventDefault();
        if (ajaxNewsForm.find("#keyword").val().length !== 0) {
            var keyword = ajaxNewsForm.find("#keyword").val();
        }
        console.log(keyword);
        jQuery.ajax({
            url: ajax_url,
            type: 'POST',
            data: {
                action: 'newsletter_ajax_filter_search_callback',
                search: keyword,
                typesPosts: 'newsletter'
            },
            success: function (response) {
                $("#datafetch .inner_posts article").remove();
                $('#datafetch .inner_posts').append(response);
                $(countAssotiatedBlocks);
                loadMoreBtnNews.hide();
            }
        });
    });

    $('form#create_event_form').submit(function (e) {

        e.preventDefault();
	    let validForm = true;

        let fd = new FormData(),
            event_recurrence_weekly = [],
            event_recurrence_yearly_month = [],
            event_tickets = [],
            all_slide_info = {},
            hero_banner = $(this).find('input[name="hero_banner"]'),
            individual_file = hero_banner[0].files[0],
            user_id = $(this).find('input[name="userid"]').val(),
            event_title = $(this).find('input[name="event_title"]').val(),
            event_desc = tinymce.get("event_desc").getContent(),
	        contact_details = tinymce.get("contact_details").getContent(),
            event_start_date = $(this).find('input[name="EventStartDate"]').val(),
            event_start_time = $(this).find('input[name="EventStartTime"]').val(),
            event_end_date = $(this).find('input[name="EventEndDate"]').val(),
            event_end_time = $(this).find('input[name="EventEndTime"]').val(),
            event_recurrence = $(this).find('select[name="type"]').val(),
            event_recurrence_daily = $(this).find('select[name="daily"]').val(),
            event_recurrence_monthly = $(this).find('select[name="monthly"]').val(),
            event_recurrence_yearly = $(this).find('select[name="yearly"]').val(),
            event_recurrence_every_yearly = $(this).find('select[name="every_yearly"]').val(),
            event_recurrence_date = $(this).find('input[name="EventRecDate"]').val(),
            event_location = $(this).find('select[name="location"]').val(),
            event_location_name = $(this).find('input[name="venue_title"]').val(),
            event_location_adress = $(this).find('input[name="venue_adress"]').val(),
            event_location_city = $(this).find('input[name="venue_city"]').val(),
            event_organization = $(this).find('select[name="organization"]').val(),
            event_rsvp_seats = $(this).find('input[name="rsvp_seats"]').val(),
            event_link = $(this).find('input[name="event_link"]').val(),
            event_series_end = $(this).find('select[name="series-end"]').val(),
            event_series_end_date = $(this).find('input[name="series-end-date"]').val(),
	        facilitators = $(this).find('select[name="facilitators"]').val(),
            event_duration = $(this).find('input[name="event_duration"]').val(),
	        url_label = $(this).find('input[name="url_label"]').val(),
	        unlimited_rsvp = $(this).find('input[name="unlimited_rsvp"]').is(':checked');



        if(!event_title) {
	        $(this).find('input[name="event_title"]').parent('.input-wrapper').addClass('error');
	        validForm = false;
        } else {
	        $(this).find('input[name="event_title"]').parent('.input-wrapper').removeClass('error');
        }

	    if(!event_duration) {
		    $(this).find('input[name="event_duration"]').parent('.input-wrapper').addClass('error');
		    const accordion_item = $(this).find('input[name="event_duration"]').closest('.accordion-item');
		    accordion_item.find('.accordion-collapse').addClass('show');
		    accordion_item.find('.accordion-button').removeClass('collapsed');
		    validForm = false;
	    } else {
		    $(this).find('input[name="event_duration"]').parent('.input-wrapper').removeClass('error');
	    }

	    /*if(!event_recurrence) {
	    	const parent = $(this).find('select[name="type"]').closest('.input-wrapper');
		    const accordion_item = $(this).find('select[name="type"]').closest('.accordion-item');
		    accordion_item.find('.accordion-collapse').addClass('show');
		    accordion_item.find('.accordion-button').removeClass('collapsed');
		    $(parent).addClass('error');
		    validForm = false;
	    } else {
		    const parent = $(this).find('select[name="type"]').closest('.input-wrapper');
		    $(parent).removeClass('error');
		    validForm = true;
	    }*/

	    if(!event_location || event_location == 'default') {
		    const parent = $(this).find('select[name="location"]').closest('.input-wrapper');
		    $(parent).addClass('error');
		    const accordion_item = $(this).find('select[name="location"]').closest('.accordion-item');
		    accordion_item.find('.accordion-collapse').addClass('show');
		    accordion_item.find('.accordion-button').removeClass('collapsed');
		    validForm = false;
	    } else {
		    const parent = $(this).find('select[name="location"]').closest('.input-wrapper');
		    $(parent).removeClass('error');
	    }

	    if(facilitators && !facilitators.length) {
		    const parent = $(this).find('select[name="facilitators"]').closest('.input-wrapper');
		    $(parent).addClass('error');
		    const accordion_item = $(this).find('select[name="facilitators"]').closest('.accordion-item');
		    accordion_item.find('.accordion-collapse').addClass('show');
		    accordion_item.find('.accordion-button').removeClass('collapsed');
		    validForm = false;
	    } else {
		    const parent = $(this).find('select[name="facilitators"]').closest('.input-wrapper');
		    $(parent).removeClass('error');
	    }

	    if(!event_desc) {
		    $(this).find('#event_desc').parent('.input-wrapper').addClass('error');
		    const accordion_item = $(this).find('#event_desc').closest('.accordion-item');
		    accordion_item.find('.accordion-collapse').addClass('show');
		    accordion_item.find('.accordion-button').removeClass('collapsed');
		    validForm = false;
	    } else {
		    $(this).find('#event_desc').parent('.input-wrapper').removeClass('error');
	    }

        let test_array = [];
        let countR = 1;
        let allSlideIndex = 1;

        $(".single_slide_inner").each(function () {
            let arraySlideinfo   = [];
            let radioVideoImage  = $(this).find(`input[name="video_for_slide_${allSlideIndex}"]:checked`).val();
            let videoVimYouCode  = $(this).find(`input[name="radio-button_vim_you_${allSlideIndex}"]:checked`).val();
	        let videoYouCode     = $(this).find('.youtube_video input[name="youtube_code_video"]').val();
	        let videoVimeoCode   = $(this).find('.vimeo_video input[name="vimeo_code_video"]').val();
            let slider_image     = $(this).find('input[name="hero_banner"]');
            let slider__file     = slider_image[0].files[0];

            if( radioVideoImage === "radio_video_next" ){
                arraySlideinfo.push('Video');
                test_array.push(slider__file);
                countR++;

                if( videoVimYouCode ) {
                    if(videoVimYouCode === 'vimeo_video') {
                        arraySlideinfo.push('Vimeo');
                        if( videoVimeoCode ) {
                            arraySlideinfo.push( GetVimeoIDbyUrl(videoVimeoCode) );
                        }
                    }else if( videoVimYouCode === 'youtube_video' ) {
                        arraySlideinfo.push('Youtube');
                        if( videoYouCode ) {
                            arraySlideinfo.push(ytVidId(videoYouCode));
                        }
                    }
                }

            } else if(radioVideoImage === "image_next"){
                arraySlideinfo.push('Image');
                fd.append('image_' + countR, slider__file);
                countR++;
            }

            all_slide_info[allSlideIndex] = arraySlideinfo;
            allSlideIndex++;
        });

        $('input[name="recurrence[rules][0][custom][week][day][]"]:checked').each(function () {
            event_recurrence_weekly.push($(this).val());
        });

        if (event_recurrence_yearly) {
            event_recurrence_yearly_month.push(event_recurrence_yearly);
            event_recurrence_yearly = event_recurrence_yearly_month;
        }

        $('.ticket-wrap .ticket').each(function (e) {
            let type = $(this).find('select').val();
            let cost = $(this).find('input[name="price"]').val();
	        let limit = $(this).find('input[name="ticket_limit"]').val();

	        event_tickets.push({
		        'limit': limit,
		        'cost' : cost,
		        'name' : type,
	        });

        });

	    if(validForm) {

		    const submitButton = $(this).find('input[type="submit"]');
		    const loader = $(this).find('.loader');
		    loader.show();

		    submitButton.attr('disabled', true);

		    fd.append('all_slide_info', JSON.stringify(all_slide_info));
		    fd.append("user_id", user_id);
		    fd.append("event_title", event_title);
		    fd.append("event_desc", event_desc);
		    fd.append("event_start_date", event_start_date);
		    fd.append("event_start_time", event_start_time);
		    fd.append("event_end_date", event_end_date);
		    fd.append("event_end_time", event_end_time);
		    fd.append("event_recurrence_daily", event_recurrence_daily);
		    fd.append("event_recurrence", event_recurrence);
		    fd.append("event_recurrence_weekly", event_recurrence_weekly);
		    fd.append("event_recurrence_monthly", event_recurrence_monthly);
		    fd.append("event_recurrence_yearly", event_recurrence_yearly);
		    fd.append("event_recurrence_every_yearly", event_recurrence_every_yearly);
		    fd.append("event_recurrence_date", event_recurrence_date);
		    fd.append("event_location", event_location);
		    fd.append("event_organization", event_organization);
		    fd.append("event_location_name", event_location_name);
		    fd.append("event_location_adress", event_location_adress);
		    fd.append("event_location_city", event_location_city);
		    fd.append("event_tickets", JSON.stringify(event_tickets));
		    fd.append("event_rsvp_seats", event_rsvp_seats);

		    fd.append("hero_banner", individual_file);
		    fd.append("event_link", event_link);
		    fd.append("event_series_end", event_series_end);
		    fd.append("event_series_end_date", event_series_end_date);
		    fd.append("event_duration", event_duration);
		    fd.append("facilitators", facilitators);
		    fd.append("contact_details", contact_details);
		    fd.append("url_label", url_label);
		    fd.append("unlimited_rsvp", unlimited_rsvp);
		    fd.append('action', 'create_event');

		    jQuery.ajax({
			    url: ajax_url,
			    data: fd,
			    type: 'POST',
			    contentType: false,
			    processData: false,
			    error: function (error) {
				    alert("Insert Failed" + error);
			    },
			    success: function (responce) {
				    const single_slide_inner = document.querySelectorAll('.single_slide_inner');
				    const rsvpSeats = document.querySelector('.js-rsvp_seats');
				    let counter = 1
				    single_slide_inner && single_slide_inner.forEach((item) => {
				    	if(counter !== 1) {
						    item.remove();
					    } else {
				    		item.querySelector('input[name="hero_banner"]').value = '';
						    item.querySelector('iframe').style.display = 'none';
					    }
					    counter++;
				    })

				    $('.overlay_message_popup').fadeIn();
				    $('.overlay-popup').fadeIn();
				    $('.popup_message_access').fadeIn();

				    $('input[type="text"]').val('');
				    let parent = $('form').find('.draghere');
				    $(parent).removeClass('active').css('background-image', '');
				    $('form').find('input[type=file]').val('');
				    submitButton.attr('disabled', false);
				    $('form#create_event_form').trigger('reset');
				    $('select[name="facilitators"]').val([]).change();
				    $('select[name="type"]').val([]).change();
				    $('select[name="location"]').val([]).change();
				    $('select[name="organization"]').val([]).change();
				    $('select[name="price_type"]').val([]).change();
				    $('#unlimited_rsvp_seats').prop('checked', false);
				    rsvpSeats.style.display = 'flex';
				    loader.hide();
			    }
		    });
	    }
    });


	function ytVidId(url) {
		var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
		return (url.match(p)) ? RegExp.$1 : '';
	}

	function GetVimeoIDbyUrl(url) {
		     let id = false;

		     $.ajax({
		         url: 'https://vimeo.com/api/oembed.json?url='+url,
		         async: false,
		         success: function(response) {
		             if(response.video_id) {
		                 id = response.video_id;
		             }
		         }
		     });
		     return id;
		 }


    /////////////////Create Library Post Submit





    /// Slider on library
    var slider_counter = 1;

    $(document).on("click", '.remove_slide', function () {
        event.preventDefault();

        $(this).closest( ".single_slide_inner" ).remove();
        if (slider_counter > 0){
            slider_counter--;
        }


        // console.log(currentSlide);
    });

    // $(document).on("click", '.insert_vid_btn', function (event) {
    //     event.preventDefault();
    //
    //     let thiselem = $(this);
    //
    //     let currentSlide = thiselem.parents().eq(3);
    //     console.log(currentSlide);
    //
    //     if ($(this).hasClass('insert_you_btn')){
    //
    //         console.log('YOU')
    //         currentSlide.find('.you_iframe').show();
    //         var textareaValue = ytVidId(currentSlide.find(".youtube_code_video").val());
    //         var link = 'https://www.youtube.com/embed/' + textareaValue;
    //         console.log(link);
    //         currentSlide.find('.you_iframe').attr('src', link);
    //
    //     }else if($(this).hasClass('insert_vim_btn')){
    //
    //         console.log('VIM')
    //         currentSlide.find('.vim_iframe').show();
    //         var textareaValue = GetVimeoIDbyUrl(currentSlide.find(".vim_code_video").val());
    //         console.log('textareaValue-  ',currentSlide.find(".vim_code_video").val());
    //         var link = 'https://player.vimeo.com/video/' + textareaValue;
    //         console.log(link);
    //         currentSlide.find('.vim_iframe').attr('src', link);
    //
    //     }else if($(this).hasClass('insert_you_list_btn')){
    //
    //         console.log('LIST YOU')
    //
    //         let textarea = checkListId(currentSlide.find(".youtube_list_code_video").val());
    //
    //         let link ='https://www.youtube.com/embed/videoseries?list='+ textarea;
    //
    //         console.log('link-',link);
    //
    //         currentSlide.find('.you_list_iframe').attr('src', link);
    //
    //         currentSlide.find('.you_list_iframe').show();
    //
    //
    //     }
    //
    //     // console.log(currentSlide);
    // });


    //
    // function checkListId(url){
    //     let video_id = url.split('list=')[1];
    //     let ampersandPosition = video_id.indexOf('&');
    //     if(ampersandPosition != -1) {
    //         video_id = video_id.substring(0, ampersandPosition);
    //     }
    //     return video_id;
    //     // console.log(video_id);
    // }
    //
    // function GetVimeoIDbyUrl(url) {
    //     var id = false;
    //     $.ajax({
    //         url: 'https://vimeo.com/api/oembed.json?url='+url,
    //         async: false,
    //         success: function(response) {
    //             if(response.video_id) {
    //                 id = response.video_id;
    //             }
    //         }
    //     });
    //     return id;
    // }
    //
    // $(".yt-url").on("click", function (e) {
    //     var ytId = ytVidId(e.target.value);
    //     alert(e.target.value + "\r\nResult: " + (!ytId ? 'false' : ytId));
    // });





    $(document).on("click", '.header-mobile .search-btn', function () {
        console.log('click');
        $('.inner_wrapper').toggleClass('disable-mob');
        $('.input_search').toggleClass('disable-mob');
    });

    $(document).on("click", '.close_search_mobile', function () {
        console.log('click');
        $('.inner_wrapper').toggleClass('disable-mob');
        $('.input_search').toggleClass('disable-mob');
    });


    $(".filter_post .filter_categories .single_cat").on("change", ".cat_chckbx", function () {
        const resultCount = document.querySelector('.result-count .count');
        const postsItems = $(".all_posts article");
        let filterPost = $(".filter_post .inner_showing_result .col_res");
        let countPosts = 0;

        if ($(this).attr("id") == "allitems") {

            if( $(this).prop("checked") ) {
                $(".all_posts article").show();

                countPosts = postsItems.filter(function() {
                    return $(this).css('display') !== 'none';
                }).length;

                if(filterPost){
                    filterPost.text(countPosts);
                }


                $(".cat_chckbx:checkbox").each(function () {

                    $(this).prop('checked', true);

                });
            }else {
                $(".all_posts article").hide();

                countPosts = postsItems.filter(function() {
                    return $(this).css('display') !== 'none';
                }).length;

                if(filterPost){
                    filterPost.text(countPosts);
                }

                $(".cat_chckbx:checkbox").each(function () {
                    $(this).prop('checked', false);
                });
            }

        } else {

            let activeCheckbox = $(".cat_chckbx:checkbox:checked");


            let selectedCbx = [];

            if(activeCheckbox){
                activeCheckbox.each(function(){
                    if($(this).attr('name') != 'allitems'){
                        selectedCbx.push("category-" + $(this).attr('name'));
                    }
                })
            }

            if(!postsItems) return false;

	        postsItems.each(function () {
		        $(this).removeClass('show');
		        $(this).css("display", "none");
	        });


            for(let i = 0; i < selectedCbx.length; i++) {
                postsItems.each(function () {
                    if($(this).hasClass(selectedCbx[i])) {
                        $(this).addClass('show');
                        $(this).css("display", "block");
                    }
                });

            }

            if(selectedCbx.length) {

                $("#allitems").prop('checked', false);

                activeCheckbox.each(function() {
	                let curentPosts = $(".type-" + $(this).attr('name'));
	                let currentTegArticle = $(".article-category-" + $(this).attr('name'));
	                //countPosts = countPosts + curentPosts.length;
	                curentPosts.show();
	                currentTegArticle.show();
                });


            } else {
                console.log('check another else');
                // $("#allitems").prop('checked', true);
                // $(".all_posts article").show();
                // countPosts = postsItems.length;
                // curentPosts.hide();
            }


	        countPosts = $(".all_posts article").filter(function() {
		        return $(this).css('display') !== 'none';
	        }).length;

	        filterPost.text(countPosts);
        }

        if(resultCount) {
            resultCount.innerText = countPosts;
        }
    });

});